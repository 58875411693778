<section class="margem-top servicos">
  <div class="servicos-titulo">Serviços digitais disponíveis na plataforma</div>
  <div class="servicos-texto">Selecione um dos serviços abaixo clicando sobre eles:</div>
  <div class="servicos-container">
    <div class="servicos-item centralizar" [class.disabled]="nivelContribuinte == 'bronze'"
      title="{{ nivelContribuinte !== 'bronze' ? 'Caixa Postal' : 'Este recurso está disponível somente para os níveis Prata e Ouro do acesso Gov.br' }}"
      (click)="nivelContribuinte !== 'bronze' && this.abrirCaixaPostal()" *ngIf="!cadastroEmUso" tabindex="0">
      <div class="row">
        <span class="servicos-item__texto-maior">CAIXA POSTAL ELETRÔNICA</span>
      </div>
      <div class="row">
        <span class="servicos-item__texto col">Comunicados oficiais da Secretaria de Fazenda</span>
      </div>
    </div>
    <div class="servicos-item centralizar" [class.disabled]="nivelContribuinte == 'bronze'"
      title="{{ nivelContribuinte !== 'bronze' ? 'Procuração Eletrônica': 'Este recurso está disponível somente para os níveis Prata e Ouro do acesso Gov.br' }}"
      [routerLink]="nivelContribuinte !== 'bronze' ? ['/procuracao'] : []" *ngIf="!cadastroEmUso">
      <div class="row">
        <span class="servicos-item__texto-maior col">PROCURAÇÃO ELETRÔNICA</span>
      </div>
      <div class="row">
        <span class="servicos-item__texto col">Outorga representação para atos junto à SEFAZ para terceiros</span>
      </div>
    </div>
    <div class="servicos-item centralizar" [class.disabled]="nivelContribuinte == 'bronze'"
      title="{{ nivelContribuinte !== 'bronze' ? 'REFIS': 'Este recurso está disponível somente para os níveis Prata e Ouro do acesso Gov.br' }}"
      [routerLink]="nivelContribuinte !== 'bronze' ? ['/refis'] : []" *ngIf="!cadastroEmUso">
      <div class="row">
        <span class="servicos-item__texto-maior col">REFIS</span>
      </div>
      <div class="row">
        <span class="servicos-item__texto col">Adesão e acompanhamento ao Programa de Recuperação Fiscal</span>
      </div>
    </div>
    <div class="servicos-item centralizar" (click)="abrirModal()" tabindex="0" *ngIf="!cadastroEmUso">
      <div class="row">
        <span class="servicos-item__texto-maior">PORTAL DE NEGOCIAÇÃO DE DÉBITOS</span>
      </div>
      <div class="row">
        <span class="servicos-item__texto col">Negociação de débitos e dívida ativa</span>
      </div>
    </div>
    <div class="servicos-item centralizar" tabindex="0" (click)="abrirModalNfae()"
      *ngIf="dadosIntegracao.sessao.cadastro.codigo == 68430 || dadosIntegracao.sessao.cadastro.codigo == 1835445 || dadosIntegracao.sessao.cadastro.codigo == 1620701">
      <div class="row">
        <span class="servicos-item__texto-maior col">NFA-e</span>
      </div>
      <div class="row">
        <span class="servicos-item__texto col subtituloNfe">Emitir, consultar, solicitar cancelamento e emitir parecer
          de notas fiscais avulsas eletrônicas</span>
      </div>
      <div class="row iconeNfe">
        <img class="iconeNfe_img"
          [src]="modoEscuro ? '../../../assets/images/icones/white-file-invoice-dollar-solid.svg' : '../../../assets/images/icones/file-invoice-dollar-solid.svg'">
      </div>
    </div>
    <div class="servicos-item centralizar" (click)="abrirPortalAcompanhamento()" tabindex="0" *ngIf="!cadastroEmUso">
      <div class="row">
        <span class="servicos-item__texto-medio cardPortalAcompanhamento">PORTAL DE ACOMPANHAMENTO DE PARCELAMENTO /
          ACORDOS</span>
      </div>
      <div class="row">
        <span class="servicos-item__texto-menor ">Acompanhar pagamento e reemissão de parcelamentos</span>
      </div>
    </div>
    <div *ngIf="dadosIntegracao.sessao.cadastro.codigo == 68430 || dadosIntegracao.sessao.cadastro.codigo == 1835445 || dadosIntegracao.sessao.cadastro.codigo == 2453487">
      <div class="servicos-item centralizar" [class.disabled]="nivelContribuinte == 'bronze'"
        title="{{ nivelContribuinte !== 'bronze' ? 'Cadastro': 'Este recurso está disponível somente para os níveis Prata e Ouro do acesso Gov.br ' }}"
        [routerLink]="nivelContribuinte !== 'bronze' ? ['/cadastro'] : []">
        <div class="row">
          <span class="servicos-item__texto-maior col">CADASTRO DE CONTRIBUINTES DO I.C.M.S</span>
        </div>
        <div class="row">
          <span class="servicos-item__texto-menor col">Cadastramento, alteração e baixa de Pessoas Físicas e Jurídicas
            fora da REDESIM</span>
        </div>
      </div>
    </div>
    <div class="servicos-item centralizar" (click)="abrirRefisIpva()" tabindex="0">
      <div class="row">
        <span class="servicos-item__texto-maior col">IPVA</span>
      </div>
      <div class="row">
        <span class="servicos-item__texto col">Emissão de DARE do IPVA<br /><br /><br /><br /><br /></span>
      </div>
    </div>
    <div class="servicos-item centralizar" (click)="abrirPerfilContribuinte()" *ngIf="!cadastroEmUso">
      <div class="row">
        <span class="servicos-item__texto-maior col">PERFIL DO CONTRIBUINTE</span>
      </div>
      <div class="row">
        <span class="servicos-item__texto col">Seus dados Fiscais, podendo adicionar e-mail para
          alertas<br /><br /><br /><br /><br /></span>
      </div>
    </div>
  </div>
  <!-- Modal Negociação de Débito -->
  <div class="backdrop" *ngIf="modalAtivo">
    <div class="div br-modal medium position-modal">
      <div class="br-modal-header">Informativo
      </div>
      <div class="br-modal-body">
        <p>O Portal de Negociação apresenta dados e dívidas ativas lançadas até a data da pesquisa, não devendo presumir
          que é uma Certidão Negativa de Débito.</p>
      </div>
      <div class="br-modal-footer justify-content-end">
        <button class="botao-modoEscuro br-button primary ml-2" type="button" (click)="abrirNegociacaoDebito()">OK
        </button>
      </div>
    </div>
  </div>

  <div class="backdrop" @abrirTermo *ngIf="backdrop">
    <div class="div br-modal medium position-modal" *ngIf="!registroTermoAdesao && termoAdesao">
      <div class="br-modal-header">Termo de Adesão
      </div>
      <div class="br-modal-body">
        <p>{{ termoAdesao }}</p>
      </div>
      <div class="br-modal-footer justify-content-end">
        <button class="br-button secondary" type="button" (click)="termoNaoAceito()">Não
        </button>
        <button class="br-button primary ml-2" type="button" (click)="termoAceito()">Sim
        </button>
      </div>
    </div>
    <div class="div br-modal large position-modal" *ngIf="registroTermoAdesao">
      <div class="br-modal-header">Concorda com os termos?
      </div>
      <div class="br-modal-body" (scroll)="scrollEnd($event)">
        <div [innerHTML]="termoAdesao" class="scroll-modal" (scroll)="scrollEnd($event)"></div>
      </div>
      <div class="br-modal-footer justify-content-center">
        <div class="row">
          <div class="br-checkbox col margin-checkbox">
            <input id="ciencia" name="ciencia" type="checkbox" [disabled]="!atBottom" (change)="termoAceito()" />
            <label for="ciencia">Li, estou ciente e de acordo.</label>
          </div>
        </div>
        <div class="row">
          <div class="br-checkbox">
            <input id="semCiencia" name="semCiencia" type="checkbox" [disabled]="!atBottom"
              (change)="termoNaoAceito()" />
            <label for="semCiencia">Li, estou ciente e não estou de acordo.</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="carregamento" *ngIf="loading">
  <img src="../../../assets/images/icones/logo-animado.svg">
</div>